import type { FilterRange, FilterState, Tag } from '~/types'
import { FilterTabType, ModuleType, TagType } from '~/enums'

export const useFilter = () => {
  const { $i18n } = useNuxtApp()
  const filterStore = useFilterStore()

  const makeH1 = (state: FilterState) => {
    const tmpTitle = []
    const tmpSubtitle = []

    const addRange = (range: FilterRange, unitKey: string) => {
      const tmp = []
      let count = 0;
      if (range.from) {
        tmp.push($i18n.t('seo.from', { val: range.from }))
        count = range.from
      }
      if (range.to) {
        tmp.push($i18n.t('seo.to', { val: range.to }))
        count = range.to
      }
      tmp.push($i18n.t(unitKey, count))

      tmpSubtitle.push(tmp.join(' '))
    }
    
    if (state.rooms.length) {
      const tag = state.rooms[0]
      
      if (tag.value.includes('room')) {
        tmpTitle.push($i18n.t('seo.with_flat_room', { val: tag.cases.accusative.toLowerCase() }))
      }
      else if (tag.value === 'free_plan') {
        tmpTitle.push($i18n.t('seo.with_flat_room_genitive', { val: tag.cases.genitive.toLowerCase() }))
      }
      else if (tag.value === 'studio') {
        tmpTitle.push($i18n.t('seo.with_flat_room_genitive', { val: tag.cases.ablative.toLowerCase() }))
      }
    }

    if (state.class.length) {
      const title = state.class[0].data?.seo?.h1?.title?.ru
      if (title) {
        tmpTitle.push(title.toLowerCase())
      }
    }

    if (state.types.length && filterStore.moduleType === ModuleType.Village) {
      const tag = state.types[0]

      const title = tag.data?.seo?.h1?.title?.ru ?? tag.cases.ablative

      tmpSubtitle.push($i18n.t('seo.with', { val: title }).toLowerCase())

      if (tag.data?.area === 'm2' && hasRange(state.area.house)) {
        addRange(state.area.house, 'unit.square_meter')
      }
      else if (tag.data?.area === 'acres' && hasRange(state.area.plot)) {
        addRange(state.area.plot, 'unit.acres_plural')
      }
    }

    if (state.highway.length) {
      tmpSubtitle.push($i18n.t('seo.on', { val: state.highway[0].cases.locative }))
    }

    if (hasRange(state.price)) {
      addRange(state.price, 'unit.currency.rub')
    }

    if (hasRange(state.mkad)) {
      addRange(state.mkad, 'unit.km_mkad')
    }

    return {
      title: tmpTitle.join(' '),
      subtitle: tmpSubtitle.join(' ')
    }
  }

  const makeUrl = (state: FilterState, path: string, page: number | undefined = undefined) => {
    const filterStore = useFilterStore()

    const urls: any[] = []
    const query = {} as any

    const append = (key: string, items: any[], useKey: boolean = false, useUrl: boolean = true) => {
      for (const [index, value] of items.entries()) {
        if (useUrl && !index && urls.length < 4) {
          urls.push(useKey ? key + '_' + value : value)
        }
        else {
          if (!query[key]) {
            query[key] = []
          }
          query[key].push(value)
        }
      }
    }

    const appendRange = (key: string, unit: string, range: FilterRange, useKey: boolean = false) => {
      if (range.from && range.to) {
        append(key, [range.from + '-' + range.to + '-' + unit], useKey)
      }
      else if (range.from) {
        append(key, ['from-' + range.from + '-' + unit], useKey)
      }
      else {
        append(key, ['to-' + range.to + '-' + unit], useKey)
      }
    }

    if (state.types.length && filterStore.moduleType === ModuleType.Village) {
      append('types', state.types.map((t: Tag) => t.slug))
    }

    if (state.class.length) {
      append('class', state.class.map((t: any) => t.slug))
    }

    if (state.highway.length) {
      append('highway', state.highway.map((t: any) => t.slug))
    }
    
    if (state.rooms.length) {
      append('rooms', state.rooms.map((t: any) => t.slug))
    }

    if (hasRange(state.mkad)) {
      appendRange('mkad', 'km', state.mkad);
    }

    if (hasRange(state.price)) {
      appendRange('price', 'rub', state.price);
    }

    const ranges = [
      { name: 'house', unit: 'm2' },
      { name: 'plot', unit: 'acres' },
      { name: 'general', unit: 'm2' },
      { name: 'kitchen', unit: 'm2' },
      { name: 'living', unit: 'm2' },
    ]
    for (const range of ranges) {
      if (hasRange(state.area[range.name])) {
        appendRange(range.name, range.unit, state.area[range.name], true);
      }
    }

    const otherTagKeys = [
      'purpose_lands', 'communications', 'heating', 'safety', 'infrastructures',
      'village_nears', 'roads', 'transports', 'materials', 'interior_decorations', 'furniture',
      'finish_types', 'house_material_types', 'residential_statuses', 'security', 'parking',
    ]

    for (const key of otherTagKeys) {
      if (
        Array.isArray(state[key as keyof FilterState]) &&
        (state[key as keyof FilterState] as Array<Tag>).length
      ) {
        append(key, (state[key as keyof FilterState] as Array<Tag>).map((t: any) => t.slug))
      }
    }
    
    if (state.finish) {
      query['finish'] = state.finish
    }
    
    if (state.metro?.length) {
      append('metro', state.metro.map((m: any) => m.title), false, false)
    }

    if (state.search) {
      query['search'] = state.search
    }
    
    if (state.search_query && Object.keys(state.search_query).length) {
      Object.keys(state.search_query).forEach(key => {
        query['search_query[' + key + ']'] = state.search_query[key]
      })
    }

    if (filterStore.tab) {
      query['tab'] = filterStore.tab
    }

    if (page !== undefined) {
      query['page'] = page
    }
    else if (filterStore.page) {
      query['page'] = filterStore.page
    }

    const url = urls.join('-')

    let fullUrl = '/' + path
    if (url) {
      fullUrl += '/' + url
    }
    if (Object.keys(query).length > 0) {
      fullUrl += '?' + new URLSearchParams(query)
    }

    return {
      url,
      fullUrl,
      query
    }
  }

  const parseUrl = (state: FilterState, path: string = '', query: any): FilterState => {
    const filterStore = useFilterStore()
    filterStore.page = query?.page ? parseInt(query.page) : 1

    const tagStore = useTagStore()
    const stateKeys = Object.keys(state)
    const pathParts = path.split('-')
    const mapTags = {
      [TagType.VillageType]: 'types',
      [TagType.VillageClass]: 'class',
      [TagType.VillageNear]: 'village_nears',
      [TagType.Communications]: 'communications',
      [TagType.Heating]: 'heating',
      [TagType.Safety]: 'safety',
      [TagType.Infrastructure]: 'infrastructures',
      [TagType.Road]: 'roads',
      [TagType.Transport]: 'transports',
      [TagType.PurposeLand]: 'purpose_lands',
      [TagType.Highway]: 'highway',
      [TagType.BuildingRoom]: 'rooms',
      [TagType.HouseMaterial]: 'materials',
      [TagType.InteriorDecoration]: 'interior_decorations',
      [TagType.Furniture]: 'furniture',
      [TagType.BuildingClass]: 'class',
      [TagType.BuildingInfrastructure]: 'infrastructures',
      [TagType.BuildingFinishType]: 'finish_types',
      [TagType.BuildingHouseMaterialType]: 'house_material_types',
      [TagType.BuildingResidentialStatus]: 'residential_statuses',
      [TagType.BuildingSecurity]: 'security',
      [TagType.BuildingParking]: 'parking',
    } as any

    let _path = path

    const replacePath = (value: string): string => {
      return _path.replace(value, '').replace('--', '-')
    }

    const addTag = (tag: Tag) => {
      const stateKey = mapTags[tag.type] ?? null
      if (!stateKey || !stateKeys.includes(stateKey)) {
        return
      }

      if (Array.isArray(state[stateKey as keyof FilterState])) {
        (state[stateKey as keyof FilterState] as Array<Tag>).push(tag)
      }
    }
    
    const findTags = () => {
      for (let i = 0; i < pathParts.length; i++) {
        const part = pathParts[i]
        
        if (i < pathParts.length - 1) {
          const partTwo = part + '-' + pathParts[i + 1]
          
          if (tagStore.cachedSlugs.has(partTwo)) {
            addTag(tagStore.cachedSlugs.get(partTwo)!)
            
            _path = _path.replace(partTwo, '').replace('--', '-')
            continue
          }
        }
        
        if (tagStore.cachedSlugs.has(part)) {
          addTag(tagStore.cachedSlugs.get(part)!)

          _path = _path.replace(part, '').replace('--', '-')
        }
      }

      for (let val of Object.values(query)) {
        if (val.includes(',')) {
          val = val.split(',')
        }
        if (Array.isArray(val)) {
          for (const slug of val as string[]) {
            if (tagStore.cachedSlugs.has(slug)) {
              addTag(tagStore.cachedSlugs.get(slug)!)
            }
          }
        }
        else if (typeof val === 'string') {
          if (tagStore.cachedSlugs.has(val)) {
            addTag(tagStore.cachedSlugs.get(val)!)
          }
        }
      }
    }

    const findRange = (key: string, range: FilterRange, regExpFromTo: RegExp, regExpFrom: RegExp, regExpTo: RegExp) => {
      const setRange = (value: string, fromIndex?: number, toIndex?: number) => {
        const parts = value.split('_')
        const tmp = (parts.length === 2 ? parts[1] : parts[0]).split('-')
        if (fromIndex !== undefined) {
          range.from = parseInt(tmp[fromIndex])
        }
        if (toIndex !== undefined) {
          range.to = parseInt(tmp[toIndex])
        }
      }

      const parseAndReplace = (match: RegExpMatchArray, fromIndex?: number, toIndex?: number): string => {
        setRange(match[0], fromIndex, toIndex)

        return replacePath(match[0])
      }

      const fields = [
        { regExp: regExpFromTo, fromIndex: 0, toIndex: 1 },
        { regExp: regExpFrom, fromIndex: 1, toIndex: undefined },
        { regExp: regExpTo, fromIndex: undefined, toIndex: 1 }
      ]

      for (const field of fields) {
        const match = path?.match(field.regExp)
        if (match) {
          _path = parseAndReplace(match, field.fromIndex, field.toIndex)
          return
        }

        if (query[key]) {
          const match = query[key][0].match(field.regExp)
          if (match) {
            setRange(match[0], field.fromIndex, field.toIndex)
          }
        }
      }
    }

    const findTab = (defaultValue: FilterTabType = FilterTabType.List) => {
      if (!query.tab) {
        filterStore.tab = defaultValue
        return
      }
      const tab = parseInt(<string>query.tab)
      if (!Object.values(FilterTabType).includes(tab)) {
        filterStore.tab = defaultValue
        return
      }

      filterStore.tab = tab
    }

    findTags()
    findTab()
    
    findRange('mkad', state.mkad, /\b\d+-\d+-km\b/g, /from-\d+-km/, /to-\d+-km/)
    findRange('price', state.price, /\b\d+-\d+-rub\b/g, /from-\d+-rub/, /to-\d+-rub/)
    findRange('house', state.area.house, /house_\d+-\d+-m2\b/g, /house_from-\d+-m2/, /house_to-\d+-m2/)
    findRange('general', state.area.general, /general_\d+-\d+-m2\b/g, /general_from-\d+-m2/, /general_to-\d+-m2/)
    findRange('kitchen', state.area.kitchen, /kitchen_\d+-\d+-m2\b/g, /kitchen_from-\d+-m2/, /kitchen_to-\d+-m2/)
    findRange('living', state.area.living, /living_\d+-\d+-m2\b/g, /living_from-\d+-m2/, /living_to-\d+-m2/)
    findRange('plot', state.area.plot, /plot_\d+-\d+_acres\b/g, /plot_from-\d+-acres/, /plot_to-\d+-acres/)
      
      
    if (query.finish !== undefined) {
      state.finish = query.finish
    }

    if (query.metro !== undefined) {
      state.metro = query.metro.split(',')
    }

    if (query.search !== undefined) {
      state.search = query.search
    }
      
    const searchQuery = {}
    Object.keys(query).forEach(key => {
      if (key.includes('search_query')) {
        const field =
          key.replace('search_query[', '').replace(']', '')
        searchQuery[field] = query[key]
      }
    })
    state.search_query = searchQuery

    if (query.page !== undefined) {
      filterStore.page = parseInt(query.page)
    }

    return state
  }

  const hasRange = (range: FilterRange) => {
    return range.from || range.to
  }

  return {
    makeH1,
    makeUrl,
    parseUrl,
    hasRange,
  }
}

